<template>
  <div
    v-if="isCallActive"
    class="call-controls fixed bottom-4 right-4 bg-white dark:bg-slate-900 p-4 rounded-lg shadow-lg z-50"
  >
    <div class="flex items-center gap-2 mb-2">
      <span class="text-sm">{{
        (callingContact && callingContact.name) || 'Unknown'
      }}</span>
    </div>
    <div class="flex items-center gap-2">
      <woot-button
        v-if="callType === 'inbound' && callEventType === 'incoming'"
        size="small"
        @click="acceptCall"
      >
        Accept
      </woot-button>
      <woot-button
        size="small"
        :variant="isMuted ? 'smooth' : 'clear'"
        :disabled="callEventType === 'incoming'"
        @click="toggleMute"
      >
        {{ isMuted ? 'Unmute' : 'Mute' }}
      </woot-button>

      <woot-button
        size="small"
        :variant="isOnHold ? 'smooth' : 'clear'"
        :disabled="callEventType === 'incoming'"
        @click="toggleHold"
      >
        {{ isOnHold ? 'Unhold' : 'Hold' }}
      </woot-button>

      <woot-button size="small" variant="alert" @click="endCall">
        End Call
      </woot-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WebRTCCallingService from './../api/webRTCCalling';

export default {
  computed: {
    ...mapGetters({
      isCallActive: 'webrtcCalling/getIsCallActive',
      isMuted: 'webrtcCalling/getIsMuted',
      isOnHold: 'webrtcCalling/getIsOnHold',
      callingContact: 'webrtcCalling/getCallingContact',
      callType: 'webrtcCalling/getCallType',
      callEventType: 'webrtcCalling/getCallEventType',
    }),
  },
  methods: {
    acceptCall() {
      WebRTCCallingService.acceptCall();
    },
    toggleMute() {
      WebRTCCallingService.toggleMute();
    },
    toggleHold() {
      WebRTCCallingService.toggleHold();
    },
    endCall() {
      WebRTCCallingService.hangupCall();
    },
  },
};
</script>
