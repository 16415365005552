// import axios from 'axios';
import ExotelCRMWebSDK from 'exotel-ip-calling-crm-websdk';

class WebRTCCallingService {
  constructor() {
    this.webPhone = null;
    this.accessToken =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6ImJiNTRlODdlLWYxNDctNDdkNC04MTk5LWVhNzBhYTg5MWMwOSIsImV4cCI6MTczNjk0NTcyOH0.v6zGJrXGryNNCbjH5qjrmKg_HZTMjsQ1XXyTzhmdwDc';
    this.userId = '1234';
    // this.accessToken = null;
    // this.userId = null;
  }

  // async fetchCredentials() {
  //   try {
  //     // TODO: split to different api so we can expiry check.
  //     const response = await axios.get('/api/v1/profile');
  //     const data = await response.json();
  //     const webrtc_calling = data.custom_attributes.webrtc_calling;
  //     this.accessToken = webrtc_calling.userToken;
  //     this.userId = webrtc_calling.userId;
  //   } catch (error) {
  //     throw new Error('Failed to fetch WebRTC credentials');
  //   }
  // }

  async initialize(handleCallEvents, registerationEvent) {
    if (this.webPhone) return this.webPhone;

    try {
      // await this.fetchCredentials();

      const crmWebSDK = new ExotelCRMWebSDK(
        this.accessToken,
        this.userId,
        true
      );
      this.webPhone = await crmWebSDK.Initialize(
        handleCallEvents,
        registerationEvent
      );
      return this.webPhone;
    } catch (error) {
      // console.error('Failed to initialize WebRTC:', error);
    }
    return this.webPhone;
  }

  static isValidPhoneNumber(phoneNumber) {
    return /^\+?[0-9]{10,14}$/.test(phoneNumber);
  }

  makeCall(phoneNumber, callback, customField) {
    if (!this.webPhone) {
      throw new Error('WebRTC not initialized');
    }

    if (!WebRTCCallingService.isValidPhoneNumber(phoneNumber)) {
      throw new Error('Invalid phone number');
    }

    return this.webPhone.MakeCall(phoneNumber, callback, customField);
  }

  hangupCall() {
    if (!this.webPhone) return;
    this.webPhone.HangupCall();
  }

  acceptCall() {
    if (!this.webPhone) return;
    this.webPhone.AcceptCall();
  }

  toggleMute() {
    if (!this.webPhone) return;
    this.webPhone.ToggleMute();
  }

  toggleHold() {
    if (!this.webPhone) return;
    this.webPhone.ToggleHold();
  }

  sendDTMF(digit) {
    if (!this.webPhone) return;
    this.webPhone.SendDTMF(digit);
  }
}

export default new WebRTCCallingService();
