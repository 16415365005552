import WebRTCCallingService from '../../api/webRTCCalling';
import ContactAPI from './../../api/contacts';

export const types = {
  SET_CALL_STATUS: 'SET_CALL_STATUS',
  SET_CALL_EVENT_TYPE: 'SET_CALL_EVENT',
  SET_CALL_TYPE: 'SET_CALL_TYPE',
  SET_DEVICE_STATUS: 'SET_DEVICE_STATUS',
  SET_CALL_DATA: 'SET_CALL_DATA',
  SET_HOLD_STATUS: 'SET_HOLD_STATUS',
  SET_MUTE_STATUS: 'SET_MUTE_STATUS',
  SET_CALLING_CONTACT: 'SET_CALLING_CONTACT',
};

export const state = {
  isCallActive: false,
  callEventType: null,
  callType: 'inbound',
  isMuted: false,
  isOnHold: false,
  isDeviceRegistered: false,
  currentCallData: null,
  callingContact: null,
};

export const getters = {
  getIsCallActive: $state => $state.isCallActive,
  getCallType: $state => $state.callType,
  getCallEventType: $state => $state.callEventType,
  getIsMuted: $state => $state.isMuted,
  getIsOnHold: $state => $state.isOnHold,
  getIsDeviceRegistered: $state => $state.isDeviceRegistered,
  getCurrentCallData: $state => $state.currentCallData,
  getCallingContact: $state => $state.callingContact,
};

async function addConversationLog(contactId, message) {
  return ContactAPI.addMessageOnLatestConversation(contactId, {
    content: message,
    private: true,
  });
}

export const actions = {
  async initializeWebRTCCalling({ commit, dispatch }) {
    try {
      await WebRTCCallingService.initialize(
        (...args) => dispatch('handleCallEvents', args),
        status => commit(types.SET_DEVICE_STATUS, status)
      );
    } catch (error) {
      // Handle initialization error
    }
  },

  async makeCall({ commit }, contact, accountId) {
    commit(types.SET_CALL_TYPE, 'outbound');
    return WebRTCCallingService.makeCall(
      contact.phone_number,
      (status, response) => {
        if (status === 'success') {
          commit(types.SET_CALL_TYPE, 'outbound');
          commit(types.SET_CALLING_CONTACT, contact);
          commit(types.SET_CALL_DATA, response.Data);
          WebRTCCallingService.acceptCall();
        }
      },
      JSON.stringify({
        account_id: accountId,
        contact_id: contact.id,
      })
    );
  },

  async handleCallEvents({ commit, state: $state }, [eventType, callData]) {
    commit(types.SET_CALL_EVENT_TYPE, eventType);
    switch (eventType) {
      case 'incoming':
        commit(types.SET_CALL_DATA, callData);
        commit(types.SET_CALL_STATUS, true);
        if ($state.callType === 'inbound') {
          // find contact with callData.callFromNumber as phone_number
          const response = await ContactAPI.getContactByPhoneNumber(
            callData.callFromNumber
          );
          const callingContact = {
            id: response.data?.id,
            name: response.data?.name || callData.callFromNumber,
            phone_number: callData.callFromNumber,
          };
          if (callingContact.id) {
            await addConversationLog(
              callingContact.id,
              'Inbound Call from ' +
                (callingContact.name === callingContact.phone_number
                  ? callingContact.phone_number
                  : callingContact.name +
                    ' (' +
                    callingContact.phone_number +
                    ')')
            );
          }
          commit(types.SET_CALLING_CONTACT, callingContact);
        }
        break;
      case 'connected':
        commit(types.SET_CALL_STATUS, true);
        if ($state.callingContact.id) {
          if ($state.callType === 'outbound') {
            await addConversationLog(
              $state.callingContact.id,
              'Outbound Call to ' +
                ($state.callingContact.name ===
                $state.callingContact.phone_number
                  ? $state.callingContact.phone_number
                  : $state.callingContact.name +
                    ' (' +
                    $state.callingContact.phone_number +
                    ')')
            );
          } else if ($state.callType === 'inbound') {
            await addConversationLog(
              $state.callingContact.id,
              'Agent connected to call'
            );
          }
        }
        break;
      case 'callEnded':
        commit(types.SET_CALL_STATUS, false);
        commit(types.SET_CALL_DATA, null);
        commit(types.SET_CALL_TYPE, 'inbound');
        if ($state.callingContact.id) {
          await addConversationLog($state.callingContact.id, 'Call Ended');
        }
        commit(types.SET_CALLING_CONTACT, null);
        break;
      case 'holdtoggle':
        commit(types.SET_HOLD_STATUS, !$state.isOnHold);
        break;
      case 'mutetoggle':
        commit(types.SET_MUTE_STATUS, !$state.isMuted);
        break;
      default:
        break;
    }
  },
};

export const mutations = {
  [types.SET_CALL_STATUS]($state, status) {
    $state.isCallActive = status;
  },
  [types.SET_CALL_EVENT_TYPE]($state, eventType) {
    $state.callEventType = eventType;
  },
  [types.SET_CALL_TYPE]($state, callType) {
    $state.callType = callType;
  },
  [types.SET_DEVICE_STATUS]($state, status) {
    $state.isDeviceRegistered = status === 'registered';
  },
  [types.SET_CALL_DATA]($state, data) {
    $state.currentCallData = data;
  },
  [types.SET_HOLD_STATUS]($state, status) {
    $state.isOnHold = status;
  },
  [types.SET_MUTE_STATUS]($state, status) {
    $state.isMuted = status;
  },
  [types.SET_CALLING_CONTACT]($state, contact) {
    $state.callingContact = contact;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
